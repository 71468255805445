<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="إضافة واحدة">
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-row>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="title"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الاسم</label>
                <b-form-input v-model="name" type="text" id="input-title" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          
            <b-col md="3" xl="3" >
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="code"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الكود</label>
                <b-form-input v-model="code" type="text" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
     

            <b-col md="3" xl="3" >
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="en_name"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الاسم باللغة الانجليزية</label>
                <b-form-input v-model="en_name" type="text" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          
           
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="addUnit">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> إضافة واحدة</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-card-code title=" واحدات">
      <!-- search input -->
     

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <!-- <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          /> -->
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>

          <!-- Column: Status -->
          <!-- <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span> -->

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'id'">
            <span>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item-button
                  @click="
                    $router.push({
                      name: 'edit-unit',
                      params: { id: props.row.id },
                    })
                  "
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>عرض</span>
                </b-dropdown-item-button>
                <!-- <b-dropdown-item-button >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>تعديل</span>
              </b-dropdown-item-button > -->
                <b-dropdown-item-button @click="deleteUnit(props.row.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item-button>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-code>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { required, max, is_earlier } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from "bootstrap-vue";
export default {


  components: {
    ToastificationContent,
    required,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      name: "",
      code:'',
      brand_name:'',
      optioneItemCategory:[],
      en_name: "",
      item_category_id:'',
      
      columns: [
      {
          label: " الاسم",
          field: "name",
        },
        {
          label: "الكود ",
          field: "code",
        },
        
        {
          label: "لاسم باللغة الانجليزية",
          field: "en_name",
        },

        {
          label: "الخيارات",
          field: "id",
          sortable: true,
        },
      ],
      rows: [],
      searchTerm: "",
      id: "",
   
    };
  },

  created() {
    this.getUnits()

  },

  methods: {
   
    deleteUnit(id) {
      this.$swal({
        icon: "warning",
        title: "هل انت متأكد من الحذف",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      }).then((result) => {
        if (result.value) {
          let url = `/api/v1/unit/${id}`;
          this.$http.delete(url).then((res) => {
            //console.log(res);
            this.getUnits()
            this.$swal({
        icon: "warning",
        title: "تم الحذف  بنجاح",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      })
            
          });
        }
      });
    },
    addUnit() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then((success) => {
            if (success) {
              let data =[]
            
                data = {
        en_name: this.en_name,
        code: this.code,
        name:this.name,
       
      };
          
      this.$http.post("/api/v1/unit", data).then((res) => {
        //console.log(res);
        this.name=""
        this.code=""
        this.en_name=""
        requestAnimationFrame(() => {
                  this.$refs.simple.reset();
                });
        this.getUnits()
        this.$swal({
          title: "",
          text: "تمت أضافة الواحدة بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
       
      });
    }})})
    },
    

    getUnits() {
      this.$http.get("/api/v1/unit").then((res) => {
        //console.log('rewards', res)
        this.rows = res.data;
      });
    },

    //
  },
};
</script> 

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>